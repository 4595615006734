.processWorkContainer {
    background-color: #60313d;
    height: 28vw;
    width: 100%;
    margin-bottom: 15vw;
}

.processWorkRow {
    display: flex;
    flex-direction: row;
}

.processWorkColumn {
    width:50%;
    text-align: left;
    position: relative;
    left: 13vw;
    top:4vw;
}

.processWorkTitle {
    margin-bottom: 0vw;
}

.processWorkContent {
    margin-bottom: 3vw;
}

.processWorkImg {
    width: 50%;
}

.honedWorkImg {
    width: 16vw;
    position: relative;
    top: -2vw;
    right: 0vw;
}

.ourWorkButton {
    background-color: #f37d68;
    width: 10vw;
    height: 3.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ourWorkLink {
    text-decoration: none;
}

.ourWorkButtonText {
    font-family: 'Open Sans', sans-serif;
    color: #60313d;
    font-size: 1.5vw;
}

@media only screen and (max-width: 500px) {
.processWorkContainer {
    height: 35vw;
    margin-bottom: 10vw;
    margin-top: 15vw;
}
.ourWorkButton {
    width: 20vw;
    height: 7.5vw;
}
.ourWorkButtonText {
    font-size: 3vw;
}
.honedWorkImg {
    top: -9vw;
    width: 30vw;
}
}

@media screen and (min-width: 1200px) {

.processWorkContainer {
    height: 20vw;
}
.ourWorkButton {
    width: 8vw;
    height: 3vw;
}
.ourWorkButtonText {
    font-size: 1vw;
}
.honedWorkImg {
    top: -5vw;
}
}