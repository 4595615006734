.toastContainer {
    background-color: #efd0dd;
    width: 25vw;
    height: 10vw;
    position: fixed;
    z-index: 50;
    left: 0;
    right: 0;
    margin: 1% auto;
    line-height: 0vw;
}

.toastTitle {
    font-family: 'DM Serif Display', serif;
    color: #f37d68;
    font-size: 3vw;
    margin-top: 3vw;
}

.toastText {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5vw;
    color: #60313d;
}