.brandingSubContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 12vw;
    padding-top: 3vw;
}

.brandingContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 40vw;
}

.brandingScreens {
    width: 20vw;
}

.brandingTitle {
    font-family: 'Open Sans', sans-serif;
    color: #fd4209;
    padding-right: 1vw;
}

.brandingContent {
    display: flex;
    flex-direction: row;
    font-family: 'Open Sans', sans-serif;
}

.brandingList {
    text-align: left;
}

.brandingLogoImg {
    margin-right: 5vw;
}

.honedLogo {
    height: 10vw;
}

.logoText {
    margin-top: 0vw;
}

.screenShots {
    width: 21.64vw;
    position: relative;
}

.screenShot1 {
    bottom: 14vw;
    right: 6vw;
}

.screenShot2 {
    bottom: 70vw;
    right: 33vw;
}

.screenShot3 {
    bottom: 54vw;
    right: 6vw;
}

.screenShot4 {
    bottom: 110vw;
    right: 33vw;
}

@media only screen and (max-width: 500px) {
    .brandingTitle {
        padding-right: 7vw;
    }
    .brandingContainer {
        margin-top: 15vw;
        margin-bottom: 90vw;
        flex-direction: column-reverse;
    }
    .brandingLogoImg {
        margin-right: 18vw;
    }
    .brandingScreens {
        display: flex;
        flex-direction: row;
        width: 25%;
        margin-top: 5vw;
    }
    .screenShots {
        position: inherit;
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
    }
}