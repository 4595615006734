#navLogo {
    height: 12vw;
    width: 12vw;
    padding-right: 0vw;
    padding-left: 1vw;
}

.NavContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.navLinks {
    display: flex;
    flex-direction: row;
    width: 35%;
    justify-content: space-between;
}

.navLink{
    text-decoration: none;
    margin-left: 1vw;
}

.navLinkText{

    font-family: 'Open Sans', sans-serif;
    color: #60313d;
    font-size: 1.2vw;
}
.highlightText{
    font-family: 'Open Sans', sans-serif;
    color: #f37d68;
    font-size: 1.2vw;
    font-weight: bold;
}

.navLinkText:hover {
    color: #f37d68;
}

@media only screen and (max-width: 1200px) {
    .navLinks{
        width: 55%;
    }
}

@media only screen and (max-width: 1000px) {
    .navLinks{
        width: 75%;
    }
}

@media only screen and (max-width: 900px) {
    .navLinks{
        width: 65%;
    }

    #navLogo{
        padding-left: 5vw;
    }

    .navLinkText {
        font-size: 2vw;
    }
    
    .highlightText{
        font-size: 2vw;
    }
}

@media only screen and (max-width: 600px) {
    .NavContainer{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 9vw;
        margin-top: 1vw;
    }

    #navLogo {
        height: 4vw;
        width: 10vw;
        padding-left: 0vw;
    }

    .navLinkText {
        font-size: 3vw;
    }

    .highlightText{
        font-size: 3vw;
    }
    .navLinks{
        width: 70%;
    }
}

@media only screen and (max-width: 500px) {

    #navLogo {
        height: 5vw;
        width: 11vw;
    }
}

@media only screen and (max-width: 400px) {
    .navLinks{
        width: 83%;
    }
}

@media screen and (min-width: 1200px) {
    #navLogo {
        height: 8vw;
        width: 8vw;
    }
    
}