
.SmallTitlePurple {
    font-size: 1.3vw;
    color: #60313d;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

.ProcessContainer {
    background-color: #f37d68;
    height: 36vw;
    width: 100%;
    padding-top: 3vw;
    margin-bottom: 11vw;
    padding-bottom: 5vw;
}

.ProcessContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15%;
}

.ProcessText {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    color: #60313d;
    line-height: 2vw;
    font-size: 1.5vw;
}

.processContentContainer {
    display: flex;
    flex-direction: row;
}

.sketchGifContainer {
    position: relative;
    top: -9vw;
    left: 12vw;
    overflow: hidden;
    height: 48.2vw;
}

.processSketchGif {
    width: 27vw;
    border-radius: 3vw;
}


.AboutButton {
    color: white;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    width: 100%;
    padding-top: 2.5vw;
}

@media only screen and (max-width: 500px) {

    .SmallTitlePurple {
        font-size: 2.1vw;
    }

    .ProcessContainer {
        height: 128vw;
        margin-bottom: 12vw;
    }

    .ProcessText {
        line-height: 4.5vw;
        font-size: 3vw;
    }
    
    
    .AboutButton {
        padding-top: 4.5vw;
    }

    .ProcessContent {
        margin-left: 10%;
    }
    
.processContentContainer {
    flex-direction: column;
}
.sketchGifContainer {
    top: 2vw;
    left: 12vw;
    border-radius: 2vw;
    overflow: hidden;
    height: 80.2vw;
}

.processSketchGif {
    width: 45vw;
    border-radius: 5vw;
}
}

@media screen and (min-width: 1200px) {

    .SmallTitlePurple {
        font-size: .7vw;
        margin-top: 1vw;
        margin-bottom: 1vw;
    }

    .ProcessContainer {
        height: 20vw;
        padding-top: 0.5vw;
    }

    .ProcessText {
        line-height: 1.5vw;
        font-size: 1vw;
    }
    
    
    .AboutButton {
        padding-top: 1.25vw;
    }

    .ProcessContent {
        margin-left: 10%;
    }
.sketchGifContainer {
    top: -1vw;
    left: 28vw;
    overflow: hidden;
    height: 30.2vw;
}

.processSketchGif {
    width: 17vw;
    border-radius: 1.3vw;
}
}