.App {
  text-align: center;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

h1 {
    font-family: 'DM Serif Display', serif;
    font-size: 5vw;
    line-height: 5vw;
    font-weight: normal;
}

h2 {
    color: #60313d;
    font-family: 'DM Serif Display', serif;
    font-size: 3.5vw;
    line-height: 4vw;
    text-align: left;
}

.orangeText {
    color: #f37d68;
}

.purpleText {
    color: #60313d;
}

.whiteText {
  color: white;
}

.centerText {
  text-align: center;
}

@media only screen and (max-width: 500px) {
  h2 {
    font-size: 5vw;
    line-height: 5vw;
    text-align: left;
}
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (min-width: 1200px) {
  h2 {
    font-size: 2vw;
    line-height: 2.2vw;
}

h1 {
    font-size: 3vw;
    line-height: 3vw;
}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
