.facePosition {
    z-index: -5;
    position: absolute;
    display: inline;
    top: 2vw;
    right: 70vw;
}

.faceShapes {
    display: flex;
    flex-direction: column;
}

.eyes {
    display: flex;
    flex-direction: row;
    margin-bottom: 3vw;
    margin-left: 2vw;
}

.leftEye {
    width: 6vw;
    height: 3vw;
    background-color: transparent;
    border-bottom-left-radius: 10vw;
    border-bottom-right-radius: 10vw;
    border: 4vw solid #efd0dd;
    border-top: 0;
    margin-left: 3vw;
}

.rightEye {
    width: 12vw;
    height: 12vw;
    border-radius: 50%;
    background-color: #efd0dd;
    margin-top: -5vw;
}

.mouth {
    width: 34vw;
    height: 16vw;
    border-bottom-left-radius: 16vw;
    border-bottom-right-radius: 16vw;
    background-color: #efd0dd;
}

@media only screen and (max-width: 500px) {
.facePosition {
    top: 3vw;
    right: 72vw;
}
}