.processMainContainer {
    background-color: #60313d;
    display: flex;
    height: 20vw;
    align-items: center;
    z-index: -20;
    position: relative;
}

#processMainText {
    color: #f37d68;
    text-align: left;
    padding-left: 15%;
    font-family: 'DM Serif Display', serif;
    font-size: 5vw;
}

@media only screen and (max-width: 500px) {
    #MainText {
        font-size: 7vw;
        padding-top: 1%;
    }

    .BannerContainer {
        line-height: 6.5vw;
    }
}

@media screen and (min-width: 1200px) {

.processMainContainer {
    height: 13vw;
    
}
#processMainText {
    padding-left: 15%;
    font-size: 3vw;
    line-height: 3vw;
}
}
