.wireframeRow {
    display: flex;
    flex-direction: row;
}

.wireframeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fd4209;
    height: 70vw;
    width: 45%;
    border-top-right-radius: 80px;
    border-bottom-left-radius: 80px;
    padding-left: 18vw;
}

.wireframeTitle {
    padding-top: 2vw;
    font-family: 'Open Sans', sans-serif;
    color: white;
}

.wireframeList {
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    color: white;
}

.wireframeSubtitle {
    font-size: 1.5vw;
}

.sketchDiv {
    width: 20%;
    height: 75vw;
}

.sketchs {
    width: 22.64vw;
    position: relative;
}

.sketch1 {
    top: 11vw;
    right: 16vw;
}

.sketch2 {
    bottom: 5vw;
    left: 8vw;
}

.sketch3 {
    bottom: 88vw;
    left: 8vw;
}

@media only screen and (max-width: 500px) {
    .wireframeSubtitle {
        font-size: 3vw;
        margin-top: 2vw;
        margin-bottom: 3vw;
    }
    .wireframeContainer {
        height: 85vw;
        width: 70%;
        padding-left: 8vw;
        padding-right: 5vw;
    }
    .sketchDiv {
        display: flex;
        flex-direction: row;
        width: 34%;
    }
    .sketchs {
        position: inherit;
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
    }
    .wireframeRow {
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) and (min-width: 500px){
    .wireframeContainer {
        height: 70vw;
        width: 55%;
        padding-left: 10vw;
    }
}