.ContactContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 15%;
    position: relative;
    margin-bottom: 10vw;
}

.ContactTextContainer {
    width: 50%;
}

.ContactText {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    color: #60313d;
    line-height: 2vw;
    font-size: 1.5vw;
    white-space: pre-wrap;
}

.contactFormDiv {
    width: 50%;
}

@media only screen and (max-width: 500px) {
    .ContactContainer {
        flex-direction: column;
        margin-left: 0;
        align-items: center;

    }
    
    .ContactText {
        line-height: 4.5vw;
        font-size: 3vw;
    }
    
    .contactFormDiv {
        width: 75%;
    }
}

@media screen and (min-width: 1200px) {


.ContactText {
    line-height: 1.5vw;
    font-size: 1vw;
}
}
