
.contactForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}



.contactFormContainer {
    width: 100%;
}

.contactText {
    height: 20vw;
    resize: none;
    font-family: 'Open Sans', sans-serif;
    color: #60313d;
}

.toastContainer {
    background-color: #efd0dd;
    width: 25vw;
    height: 10vw;
    position: fixed;
    z-index: 50;
    line-height: 0vw;
}

.toastTitle {
    font-family: 'DM Serif Display', serif;
    color: #f37d68;
    font-size: 3vw;
    margin-top: 3vw;
}

.toastText {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5vw;
    color: #60313d;
}

.toastDiv {
    width: 100px;
    height: 100px;
    position: fixed;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    background-color: #60213d;
}

.inputDiv {
    text-align: left;
}

input, label {
    display: block;
}

label {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1vw;
    color: #60313d;
    margin-top: 1vw;
}

input, textarea {
    border-radius: 0;
    border: 1px solid #cccccc;
    width: 30vw;
    height: 2vw;
    font-family: 'Open Sans', sans-serif;
    padding-left: 1vw;
    font-size: 1vw;
}

textarea {
    padding-top: .5vw;
    padding-right: .5vw;
}

.submitButton{
    color: #60313d;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5vw;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
}
.submitButton:hover {
    cursor: pointer;
}

.formButtonContainer{
    margin-top: 3vw;
    height: 5vw;
    width: 12vw;
    background-color: #f37d68;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 500px) {
    input, textarea {
        width: 60vw;
        height: 4vw;
        font-size: 3vw;
    }

    label {
        font-size: 2.5vw;
    }

    .submitButton{
        font-size: 3vw;
    }

    .formButtonContainer{
        margin-top: 3vw;
        height: 10vw;
        width: 24vw;
        align-self: flex-start;
        margin-left: 6.7vw;
    }
    .contactFormContainer {
        margin-left: 8%;
    }
}

@media screen and (min-width: 1200px) {

    input, textarea {
        font-size: 1vw;
    }

    label {
        font-size: 1.1vw;
    }

    .submitButton{
        font-size: 1vw;
        padding-left: .5vw;
    }

    .formButtonContainer{
        margin-top: 3vw;
        height: 3vw;
        width: 8vw;
    }
}
