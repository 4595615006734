.smallStepsContainer {
    display: flex;
    flex-direction: row;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 8vw;
    height: 22vw;
}

.rowSteps{
    z-index: 10;
}

.iconContainer {
    height: 42px;
    width: 17vw;
}

.tagIcon {
    margin-top: 1vw;
}

.numberTitle {
    color: #60313d;
    margin-bottom: 1vw;
    font-size: 1.25vw;
}

.stepTitles {
    color: #f37d68;
    margin-top: .75vw;
    font-weight: normal;
    font-size: 1.5vw;
}

@media only screen and (max-width: 600px) {
    .smallStepsContainer {
        margin-left: 2vw;
        margin-right: 2vw;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 500px) {
    .smallStepsContainer {
        flex-direction: column;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 8vw;
        margin-bottom: 15vw;
        height: 68vw;
    }
    .rowSteps {
        display: flex;
        flex-direction: row;
        margin-bottom: 2vw;
        justify-content: left;
        align-items: center;
    }
    .numberTitle {
        margin-top: 0vw;
        margin-left: 0vw;
        font-size: 2vw;
    }
    .stepTitles {
        margin-left: 5vw;
        margin-top: 3vw;
        text-align: left;
        font-size: 2.5vw;
    }
    .tagNumber {
        margin-left: 0vw;
    }
    .smallStepIcon {
        height: 6vw;
    }
}

@media screen and (min-width: 1200px) {
    .numberTitle {
        font-size: .75vw;
    }
    .stepTitles {
        font-size: 1vw;
    }
    .smallStepsContainer {
        height: 16vw;
    }
    .smallStepIcon {
        height: 3vw;
    }
    .tagIcon {
        margin-top: 0vw;
    }
}