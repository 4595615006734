.ideationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vw;
}

.workLogoImg {
    height: 12vw;
    width: 12vw;
}

.ideationTitle {
    color: #fd4209;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 3.5vw;
    margin-top: 0;
}

.ideationLogo {
    height: 10vw;
}

.ideationText {
    width: 46vw;
    font-size:1.5vw;
    color: #696969;
    line-height: 2vw;
}

@media only screen and (max-width: 500px) {
.ideationText {
    width: 60vw;
    font-size:3vw;
    line-height: 4.4vw;
}
.ideationTitle {
    font-size: 4.5vw;
}
}