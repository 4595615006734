.aboutIntroContainer {
    display: flex;
    flex-direction: column;
    height: 77vw;
    margin-top: 2vw;
}

.aboutIntroPhoto {
    margin: auto;
    margin-top: 0;
    margin-bottom: -4px;
}

.aboutPhotoContainer {
    background-color: #60313d;
    z-index: -20;
}

.aboutImg {
    height: auto;
    width: 100%;
}

.aboutIntroTextDiv {
    height: 16vw;
    z-index: 5;
}

.aboutIntroText {
    text-align: left;
    padding-left: 14vw;
    margin: 0;
    margin-top: 2vw;
    color: #60313d;
}

@media screen and (min-width: 1200px) {

.aboutIntroPhoto {
    max-width: 1100px;
}

.aboutIntroTextDiv {
    height: 10.7vw;
}

.aboutIntroContainer {
    height: 950px;
    margin-top: 0vw;
}
}