.AboutContainer{
    height: 30vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vw;
}

#aboutText{
    color: hsl(9, 85%, 68%);
    font-size: 1.75vw;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}

.aboutButton{
    color: #60313d;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5vw;
    width: 100%;
    height: 100%;
    padding-top: 2.5vw;

}

.aboutButtonContainer{
    height: 5vw;
    width: 12vw;
    background-color: #f37d68;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 500px) {

    #aboutText{
        font-size: 3vw;
    }

    .aboutButton{
        padding-top: 5.5vw;
        font-size: 3vw;
    
    }
    
    .aboutButtonContainer{
        height: 10vw;
        width: 24vw;
    }

    .AboutContainer{
        height: 30vw;
        padding-top: 5vw;
        margin-bottom: 15vw;
    }
}


@media screen and (min-width: 1200px) {

    #aboutText{
        font-size: 1.25vw;
    }

    .aboutButton{
        padding-top: 1.3vw;
        font-size: 1vw;
    
    }
    
    .aboutButtonContainer{
        height: 3vw;
        width: 8vw;
    }

    .AboutContainer{
        height: 6vw;
        padding-top: 3vw;
        margin-bottom: 13vw;
    }
}





