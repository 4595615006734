.moreWork {
    font-size: 1.5vw;
    color: #60313d;
    padding-top: 2.5vw;
    margin-bottom: 0vw;
}

.moreWorkDiv {
    background-color: #f2f2f2;
    margin-bottom: 3.5vw;
}
@media only screen and (max-width: 500px) {
    .moreWork{
    font-size: 2.5vw;
    }
}