.BannerContainer {
    line-height: 5vw;
    background-color: #60313d;
    display: flex;
    width: 100%;
    padding-top: 40%;
    align-items: center;
    position: relative;
    z-index: -20;
}

#MainText {
    color: #f37d68;
    text-align: left;
    padding-left: 15%;
    padding-top: 5%;
    font-family: 'DM Serif Display', serif;
    font-size: 5vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@media only screen and (max-width: 500px) {
    #MainText {
        font-size: 7vw;
        padding-top: 1%;
        line-height: 7vw;
    }

}

@media screen and (min-width: 1200px) {
    #MainText {
        font-size: 3vw;
        padding-top: 1%;
        line-height: 3vw;
    }

.BannerContainer {
    width: 100%;
    padding-top: 20%;
}
}
