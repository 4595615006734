.aboutContentContainer {
    display: flex;
    flex-direction: column;
    margin-top: 3vw;
}

.aboutLeftDiv {
    display: flex;
    flex-direction: row;
}

.aboutRightDiv {
    display: flex;
    flex-direction: row;
    margin-top: -5vw;
}

.aboutLeft {
    width: 50%;
}

.leftMargin {
    margin-left: 8vw;
}

.topMargin {
    margin-top: 1vw;
}

.aboutRight {
    width: 50%;
}

.aboutRightTop {
}

.aboutRightBottom {
    margin-top: -2vw;
}

.aboutLeftBottom {
    margin-top: 2vw;
}

.aboutBottomText {
    margin-top: 5vw;
    margin-bottom: 5vw;
    background-color: #60313d;
}

.smallAboutText {
    text-align: left;
    font-size: 1.6vw;
    line-height: 2.2vw;
    margin-left: 4vw;
    margin-right: 6vw;
}

@media only screen and (max-width: 500px) {

.aboutContentContainer {
    margin-top: 3vw;
}
.aboutLeftDiv {
    flex-direction: column;
    align-items: center;
}

.aboutRightDiv {
    flex-direction: column;
    align-items: center;
    margin-top: 1.75vw;
}

.ockShapes {
    position: absolute;
    top: 85vw;
    left: 5vw;
}

.lloShapes {
    position: absolute;
    top: 100vw;
    left: 52vw;
}
.aboutLeft {
    width: 75%;
}

.aboutRight {
    width: 75%;
}
.smallAboutText {
    font-size: 2vw;
    line-height: 2.5vw;
}
}


@media screen and (min-width: 1200px) {

.smallAboutText {
    font-size: 1vw;
    line-height: 1.5vw;
    margin-top: 5vw;
}

.aboutLeftBottom {
    margin-top: 0vw;
}
}

/* Shapes */


.ockShapes {
    display: flex;
    flex-direction: row;
    height: 15vw;
    justify-content: flex-end;
}

.lloShapes {
    display: flex;
    flex-direction: row;
    margin-top: -8vw;
}

.oShape {
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    background-color: #f2f2f2;
    z-index: -5;
}

.cShape {
    width: 9vw;
    height: 18vw;
    background-color: #f2f2f2;
    border-top-left-radius: 9vw;
    border-bottom-left-radius: 9vw;
    margin-left: 3vw;
    z-index: -5;
}

.kShape {
    margin-left: 3vw;
    z-index: -5;

}

.topTriShape{
    width: 0;
    height: 0;
    border-top: 8.5vw solid #f2f2f2;
    border-right: 10vw solid transparent;
    margin-bottom: 1vw;
    z-index: -5;
}

.bottomTriShape {
    width: 0;
    height: 0;
    border-right: 10vw solid transparent;
    border-bottom: 8.5vw solid #f2f2f2;
    z-index: -5;
}

.lShape {
    width: 6vw;
    height: 18vw;
    background-color: #f2f2f2;
    margin-right: 4vw;
    z-index: -5;
}