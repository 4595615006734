.workNavigationContainer {
    display: flex;
    flex-direction: row;
    margin-left: 24vw;
}

.active {
    margin-right: 8vw;
    font-weight: bold;
    color: #f37d68;
    border-bottom: 2px solid #f37d68;
    padding-bottom: 7px;
    font-size: 2.25vw;
}

.inactive {
    margin-right: 8vw;
    font-weight: normal;
    color: #60313d;
    cursor: pointer;
    font-size: 2.25vw;
}


@media only screen and (max-width: 500px) {

.active {
    font-size: 3.25vw;
}

.inactive {
    font-size: 3.25vw;
}

.workNavigationContainer{
    margin-bottom: 4vw;
}
}

@media screen and (min-width: 1200px) {

.active {
    font-size: 1.25vw;
}

.inactive {
    font-size: 1.25vw;
}
}