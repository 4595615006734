.WorkMainBanner {
    background-color: #60313d;
    height: 20vw;
    display: flex;
    align-items: center;
    z-index: -20;
    position: relative;
}

.WorkMainBannerText {
    padding-left: 14vw;
}
@media screen and (min-width: 1200px) {

.WorkMainBanner {
    height: 11vw;
}
}