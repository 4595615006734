.WorkContainer {
    display: flex;
    justify-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 100px;
}

.SmallTitle {
    font-size: 1.1vw;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

.TwoColumns {
    display: flex;
    flex-direction: row;
}

.LeftColumn {
    width: 40%;
    margin-left: 15%;
    text-align: left;
}

.TwoColList {
    display: flex;
    flex-direction: row;
    color: #60313d;
}

.LeftColList {
    width: 50%;
}

.listTitle {
    font-size: .9vw;
    font-weight: bold;
}

.listItem {
    line-height: 1vw;
}

.noDisk {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 1.5vw;
}

.PurpleBtnDiv {
    margin-top: 2vw;
    width: 12vw;
    height: 5vw;
    font-size: 1.5vw;
    background-color: #60313d;  
    display: flex;
    align-items: center;
    justify-content: center;
}

.PurpleButton {
    color: white;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    width: 100%;
    padding-top: 2.5vw;
    padding-left: 2.75vw;
}

.workDescription {
    font-family: 'Open Sans', sans-serif;
    line-height: 2vw;
    font-size: 1.5vw;
    padding-bottom: 1vw;
}

.TwoColList {
    font-family: 'Open Sans', sans-serif;
}

.appImg {
    width: 34vw;
    height: 66vw;
}

@media only screen and (max-width: 500px) {

    .appImg {
        padding-top: 15vw;
        width: 54vw;
        height: 103vw;
    }

    .TwoColumns {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SmallTitle {
        font-size: 2.1vw;
    }

    .LeftColumn {
        width: 80%;
        margin-left: 0;
    }

    .PurpleBtnDiv {
        width: 24vw;
        height: 10vw;
        font-size: 3vw;
    }
    
    .PurpleButton {
        padding-top: 5vw;
        padding-left: 5vw;
    }

    .listTitle {
        font-size: 2vw;
    }

    .noDisk {
        font-size: 3vw;
    }

    .workDescription {
        line-height: 4.5vw;
        font-size: 3vw;
        padding-bottom: 1vw;
    }
}

@media screen and (min-width: 1200px) {

    .appImg {
        width: 17vw;
        height: 32vw;
        padding-left: 5vw;
    }

    .SmallTitle {
        font-size: .7vw;
    }

    .PurpleBtnDiv {
        width: 8vw;
        height: 3vw;
        font-size: 1vw;
    }
    
    .PurpleButton {
        padding-top: 1.5vw;
        padding-left: 1.5vw;
    }

    .listTitle {
        font-size: 0.9vw;
    }

    ul {
        font-size: 1vw;
    }

    .workDescription {
        line-height: 1.5vw;
        font-size: 1vw;
        padding-bottom: 1vw;
    }
}