.techContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 34vw;
    margin-bottom: 10vw;
}

.techContent {
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
    margin-top: 6vw;
    font-family: 'Open Sans', sans-serif;
}

.techTextContainer {
    background-color: #fd4209;
    width: 48vw;
    height: 43vw;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
}

.techTitle {
    font-family: 'Open Sans', sans-serif;
    color: White;
}

.techImgContainer {
    display: flex;
    flex-direction: row;
}

.techImg {
    width: 23vw;
    height: 39vw;
    position: relative;
    right: 15vw;
    top: 4vw;
    overflow: hidden;
}

.techImg2 {
    width: 23vw;
    height: 39vw;
    position: relative;
    right: 15vw;
    top: 13vw;
    overflow: hidden;
}

.fullGif2 {
    width: 25vw;
    position: relative;
    right: 9vw;
    top: -12vw;
}

.fullGif1 {
    width: 25vw;
    position: relative;
    right: 14vw;
    top: 6vw;
}

.techSquare {
    background-color: #fd4209;
    width: 35vw;
    height: 35vw;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
}

.techList {
    text-align: left;
}

@media only screen and (max-width: 500px) {
    .techContent {
        margin-right: 15vw;
        margin-top: 5vw;
    }
    .techTextContainer {
        width: 75vw;
        height: 55vw;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
    }
    .fullGif1 {
        position: inherit;
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
    }
    .fullGif2 {
        position: inherit;
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
    }
    .techContainer {
        margin-bottom: 30vw;
        flex-direction: column;
    }

    .techImgContainer{
        width: 50%;
    }
}