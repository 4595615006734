#rectangleOne {
    width: 10vw;
    height: 3vw;
    top: 14vw;
    left: 80%;
    background-color: #774e55;
    position: absolute;
    transform: rotate(65deg);
    z-index: 0;
}

#rectangleOneAbout {
    width: 10vw;
    height: 3vw;
    top: 20vw;
    left: 0%;
    background-color: #f37d68;
    position: absolute;
    transform: rotate(120deg);
    z-index: -10;
}

#circleOutlineOne {
    position: absolute;
    top: 10vw;
    right: 0;
    z-index: 0;
    height: 15vw;
}

#circleOutlineThree {
    border: 3vw solid #f37d68;
    background-color: transparent;
    height: 10vw;
    border-radius: 50%;
    width: 10vw;
    position: absolute;
    top: 104vw;
    left: 81%;
    z-index: -5;
}

#circleOutlineThree2 {
    border: 3vw solid #f37d68;
    background-color: transparent;
    height: 10vw;
    border-radius: 50%;
    width: 10vw;
    position: absolute;
    top: 130vw;
    left: 81%;
    z-index: -5;
}

#circleOutlineFour {
    border: 8vw solid #f37d68;
    background-color: transparent;
    height: 30vw;
    border-radius: 50%;
    width: 30vw;
    position: absolute;
    top: 156vw;
    left: -19%;
    z-index: -5;
}

#circleOutlineFour2 {
    border: 8vw solid #f37d68;
    background-color: transparent;
    height: 30vw;
    border-radius: 50%;
    width: 30vw;
    position: absolute;
    top: 185vw;
    left: -32%;
    z-index: -5;
}

#halfCircleOne {
    border-top-left-radius: 10vw;
    border-top-right-radius: 10vw;
    background-color: #efd0dd;
    width: 10vw;
    height: 5vw;
    transform: rotate(-30deg);
    position: absolute;
    top: 28vw;
    left: 85%;
    z-index: 0;
}

#circleOutlineTwo {
    border: 3vw solid #efd0dd;
    background-color: transparent;
    height: 10vw;
    border-radius: 50%;
    width: 10vw;
    position: absolute;
    top: 42vw;
    right: 92%;
    z-index: 0;
}

#halfCircleTwo {
    border-top-left-radius: 10vw;
    border-top-right-radius: 10vw;
    background-color: #60313d;
    width: 10vw;
    height: 5vw;
    transform: rotate(-30deg);
    position: absolute;
    top: 63vw;
    right: 86%;
    z-index: -10;
}

#halfCircleThree {
    border-top-left-radius: 10vw;
    border-top-right-radius: 10vw;
    background-color: #60313d;
    width: 10vw;
    height: 5vw;
    transform: rotate(-30deg);
    position: absolute;
    top: 105vw;
    right: 92%;
    z-index: -10;
}

#rectangleTwo {
    width: 10vw;
    height: 3vw;
    top: 60vw;
    right: 0;
    background-color: #f37d68;
    position: absolute;
    transform: rotate(-65deg);
    z-index: 0;
}

#rectangleTwoAbout {
    width: 10vw;
    height: 3vw;
    top: 60vw;
    left: 90%;
    position: absolute;
    transform: rotate(-65deg);
    z-index: -10;
    background-color: #f37d68;
}

#bigSolidCircle {
    width: 60vw;
    height: 60vw;
    background-color: #efd0dd;
    border-radius: 50%;
    position: absolute;
    top: 90vw;
    right: 0;
    z-index: -10;
}

#bigSolidCircle2 {
    position: absolute;
    top: 118vw;
    height: 50vw;
    right: 0;
    z-index: -10;
}

.imgScale {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

@media only screen and (max-width: 500px) {
    #bigSolidCircle {
        width: 0vw;
        height: 0vw;
        top: 214vw;
        left: 73%;
    }
    #bigSolidCircle2 {
        width: 0px;
        height: 0px;
        top: 190vw;
        background-color: transparent;
    }
    #circleOutlineThree {
        height: 10vw;
        width: 10vw;
        top: 230vw;
        left: 81%;
    }
    #circleOutlineThree2 {
        height: 0px;
        width: 0px;
        top: 197vw;
        border: 0px solid transparent;
    }
    #rectangleOne {
        top: 45vw;
    }

    #circleOutlineOne {
        top: 36vw;
    }

    #rectangleTwoAbout {
        left: 90%;
    }
}

@media screen and (min-width: 1200px) {
    #halfCircleTwo {
        top: 63vw;
        right: 93%;
    }

    #circleOutlineFour2 {
        top: 180vw;
    }

}