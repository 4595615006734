.stepsContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.stepTitle {
    margin-top: 0;
}

.stepNumber {
    margin-bottom: 1.1vw;
}

.stepsRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 10vw;
}

.stepsRowReverse {
    display: flex;
    flex-direction: row;
    margin-bottom: 10vw;
}

.stepsRowText {
    width: 60%;
    text-align: left;
    margin-right: 6vw;
}

.stepsRowImg {
    width: 32vw;
    height: 40vw;
    background-color: gray;
}

.sketchGif {
    width: 16vw;
}

.phoneGif {
    width: 18vw;
    position: relative;
    left: -3vw;
    top: -2.25vw
}

.phoneGifContainer {
    box-shadow: -1vw 1vw 2vw #ccc;
    width: 13vw;
    height: 27.6vw;
    margin-right: 4vw;
    margin-bottom: 3vw;
    z-index: 10;
}

.designGif {
    width: 22vw;
    height: 37vw;
}

.stepText {
    line-height: 2.5vw;
    font-size: 1.5vw;
    width: 36vw;
}

.stepFiveRow {
    height: 25vw;
    margin-bottom: 5vw;
}

.centerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 500px) {
    .stepsRow {
        flex-direction: column;
        align-items: center;
        margin-bottom: 2vw;
    }
    .stepsRowReverse {
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 2vw;
    }
    .stepsRowText {
        width: 80%;
        text-align: center;
        margin-right: 0vw;
        margin-bottom: 6vw;
    }
    .stepTitle {
        text-align: center;
    }
    .stepsRowImg {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stepText {
        line-height: 4.5vw;
        font-size: 3vw;
        width: 100%;
    }
    .sketchGif {
        width: 45vw;
    }
    .phoneGif {
        width: 45vw;
        left: -5.3vw;
        top: -4.5vw
    }
    .phoneGifContainer {
        width: 35vw;
        height: 73.6vw;
        margin-right: 0vw;
    }
    .stepNumber {
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 1200px) {
    .stepText {
        line-height: 1.5vw;
        font-size: 1vw;
    }
    .stepsRowImg {
        width: 22vw;
        height: 30vw;
    }
    .stepsRow {
        margin-bottom: 0vw;
    }
    .stepsRowReverse {
        margin-bottom: 0vw;
    }
}